<template>
  <div id="app">
    <div class="jbmb">
      <div class="banner">
        <h1>简报模版是用户输入的简报名称</h1>
        <p><span>报告于2019-03-06 09:00由舆情管理系统生成</span></p>
      </div>
      <div class="cont">
        <h2>一、舆情总览</h2>
        <p>
          9月17日16时至9月18日16时，全网与[宜华]相关的舆情信息共计7121条，其中，微博（3657）、微信（2421）、网媒（953）、论坛（78）、报刊（9）、博客（3）。总体来看，今日宜华相关舆情信息总量持续回升，属于在正常范围内波动。</p>
        <!-- 信息来源分布 -->
        <el-card class="box-card mod-echarts-box mar-t-20 xxslzs">
          <h3 class="mod-title">信息来源分布</h3>
          <div class="echarts_empty hide">
            <div class="img"></div>
          </div>
          <div id="mediaSourceMap" class="mod-echarts-cont pad-lr-20">
          </div>
        </el-card>
        <!--@end 信息来源分布 -->
        <p>
          今日与宜华、宜华健康、宜华生活等相关的负面敏感信息占比为31%，主要来源于“宜华健康回复深交所关注函的消息”，今日敏感信息处于近期最高值，整体舆论环境较为负面。已经密切关注舆情变化，及时进舆情预警，启动舆情管理和净化的应急机制。</p>
        <!-- 敏感信息占比 -->
        <el-card class="box-card mod-echarts-box mar-t-20 xxslzs">
          <h3 class="mod-title"><span class="red">敏感</span>信息占比</h3>
          <div class="echarts_empty hide">
            <div class="img"></div>
          </div>
          <div id="sensitive" class="mod-echarts-cont pad-lr-20">
          </div>
        </el-card>
        <!--@end 敏感信息占比 -->
        <p>
          根据舆情监控系统生成的宜华相关关键词云来看，今日关键热门词主要包括：“宜华健康、众安康、养老、合同、项目、关注函”等，出自《宜华健康收深交所关注函核查子公司相关养老项目》、《关于对宜华健康医疗股份有限公司的关注函》的回复、《宜华健康回复关注函：康馨养老项目不存在完全停工状态》、《宜华健康：“高处坠落事故”对公司整体经营影响有限》等报道。</p>
        <!-- 热门关键词 -->
        <el-card class="box-card mod-echarts-box mar-t-20 xxslzs">
          <h3 class="mod-title">热门关键词</h3>
          <div class="echarts_empty hide">
            <div class="img"></div>
          </div>
          <div id="words" class="mod-echarts-cont pad-lr-20">
          </div>
        </el-card>
        <!--@end 热门关键词 -->
        <h2>二、舆情重点</h2>
        <h3 class="h3">今日的舆情重点主要来自对“媒体和市场对于宜华健康对深交所关注函回复”的关注，媒体主要对直接转载关注函回复内容本身、收关注函、坠落事故等进行了报道。</h3>
        <h3 class="h3">（一）21新健康（Healthnews21）（微信）发布的“宜华健康收深交所关注函核查子公司相关养老项20”成为今日最热门报道。</h3>
        <p>文中称：9月17日，宜华健康（000150.SZ）发布《关于对深圳证券交易所关注函的回复》公告。</p>
        <h3 class="h3">（二）新京报发布的“宜华健康回复关注函：康馨养老项目不存在完全停工状态28”受诸多媒体关注</h3>
        <p>文中提到：9月18日，上市公司宜华健康就一个多月前收到的一份关注函进行了回应。根据公司的回应，其旗下此前被媒体质疑已停工的康馨养老项目“不存在完全停工状态”。</p>
        <h3 class="h3">（三）雪球发布的“宜华健康：“高处坠落事故”对公司整体经营影响有限”</h3>
        <p>
          宜华健康称，“康馨养老项目，因2017年7月发生一起高处坠落事故后，为了配合事故调查，严格自查自检加强安全管理，临时停工整改约两个月；因项目地质复杂，调整施工方，新老施工方于2018春节前后暂停施工交接外，均持续施工。不存在完全停工的状态。”宜华健康还表示：上述因偶发工程安全事故，临时整改事件，对项目工期有一定影响。对公司整体经营影响有限。鉴于以上事实，不存在不安要求履行公告披露义务的情形。</p>
        <h2>三、下一步工作</h2>
        <p><strong>（一）是严密监控舆情。</strong>我们将继续做好监测工作，在合适的传播节点进行正面新闻的投放和中立态度情绪的引导。对于已经存在的负面报道，我们目前已经及时对传播渠道进行沟通和管理，降低传播速度，控制影响范围。
        </p>
        <p><strong>（二）是做好股吧维护。</strong>在股吧内连续多日进行了正面消息投放，效果十分良好。我们将继续做好股吧维护，针对负面消息及时管理和稀释，并继续在恰当时机进行正面消息的推送和引导工作。</p>
      </div>
    </div>
  </div>
</template>
<script>
  import {globalGetEcharts, globalMediaColor} from "@/utils/helpers";
  export default {
    name: 'brief-daily-common',
    data() {
      return {
        sourcedata: [{"name": "微博", "num": 3657, "sort": 1, "rate": 51.36}, {
          "name": "微信",
          "num": 2421,
          "sort": 2,
          "rate": 34
        }, {"name": "网媒", "num": 953, "sort": 3, "rate": 13.38}, {
          "name": "论坛",
          "num": 78,
          "sort": 4,
          "rate": 1.1
        }, {"name": "报刊", "num": 9, "sort": 5, "rate": 0.13}, {"name": "博客", "num": 3, "sort": 6, "rate": 0.04}],
        sensitive: {"negative": 31, "positive": 69}
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.source()
        this.sen()
        this.treemap()
      })
    },
    methods: {
      source() {
        var sourcedata = this.sourcedata;
        var data = sourcedata
        var kMaps = {}
        var total = 0
        var keys = []
        var echarts = globalGetEcharts();
        data.map(function (item) {
          total += item.num
        })
        data = data.map(function (item) {
          var rate = (item.num / total) * 100
          rate = rate.toFixed(2).toString()
          kMaps[item.name] = rate + "% " + item.num + "条"
          keys.push(item.name)
          item.itemStyle = {color: globalMediaColor(item.name)}
          item.value = item.num
          return item
        })
        var option = {
          animation: false,
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          legend: {
            orient: 'vertical',
            left: '30',
            bottom: "0",
            data: keys,
            itemWidth:10,
            itemHeight:10,
            itemGap:5,
            icon: "circle",
            formatter: function (name) {
              return name + " " + kMaps[name]
            }
          },
          series: [
            {
              name: '来源分布',
              type: 'pie',
              radius: ['45%', '55%'],
              center: ['50%', '40%'],
              data: data,
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                },
                normal: {
                  label: {
                    show: false
                  },
                  labelLine: {
                    show: false
                  }
                }
              }
            }
          ]
        };
        var map = window.document.getElementById('mediaSourceMap');
        if (!map) return false
        var myChart = echarts.init(map);
        myChart.setOption(option);
      },
      sen() {
        let map = window.document.getElementById('sensitive')
        var echarts = globalGetEcharts()
        var myChart = echarts.init(map);
        var sensitive = this.sensitive;
        var result = sensitive
        myChart.setOption({
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c} ({d}%)"
          },
          legend: {
            orient: 'vertical',
            // x: 'left',
            data: ['敏感', '非敏感'],
            bottom: "0",
            left: "20px",
            itemWidth:10,
            itemHeight:10,
            itemGap:5,
            icon: "circle"
          },
          color: ['#FC5D73','#555DFE'],
          series: [
            {
              name: '情感分布',
              type: 'pie',
              center: ['50%', '45%'],
              radius: ['45%', '60%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: '30',
                    fontWeight: 'bold'
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: [{name: "敏感", value: result.negative}, {name: "非敏感", value: result.positive}]
            }
          ]
        })
      },
      treemap() {
        let map = window.document.getElementById('words')
        var echarts = globalGetEcharts();
        var myChart = echarts.init(map);
        myChart.setOption({
          tooltip: {
            formatter: function (info) {
              return `${info.name}：${info.value}`;
            }
          },
          series: [{
            type: 'treemap',
            breadcrumb: {
              show: false
            },
            data: [{
              name: '热门关键词',            // First tree
              children: [{
                name: '宜华健康',       // First leaf of first tree
                value: 7409,
                itemStyle: {
                  color: '#FEBA86'
                }
              }, {
                name: '众安康',       // Second leaf of first tree
                value: 6780,
                itemStyle: {
                  color: '#26B3D8'
                }
              }, {
                name: '养老',       // Second leaf of first tree
                value: 6800,
                itemStyle: {
                  color: '#76D4FF'
                }
              }, {
                name: '合同',       // Second leaf of first tree
                value: 6500,
                itemStyle: {
                  color: '#26B3D8'
                }
              }, {
                name: '项目',       // Second leaf of first tree
                value: 5800,
                itemStyle: {
                  color: '#888888'
                }
              }, {
                name: '关注函',       // Second leaf of first tree
                value: 5500,
                itemStyle: {
                  color: '#62AFFE'
                }
              }, {
                name: '资不抵债',       // Second leaf of first tree
                value: 5500,
                itemStyle: {
                  color: '#888888'
                }
              }, {
                name: '限制',       // Second leaf of first tree
                value: 5410,
                itemStyle: {
                  color: '#FEBA86'
                }
              }, {
                name: '法院',       // Second leaf of first tree
                value: 210,
                itemStyle: {
                  color: '#26B3D8'
                }
              }, {
                name: '一审',       // Second leaf of first tree
                value: 110,
                itemStyle: {
                  color: '#76D4FF'
                }
              }, {
                name: '亏损',       // Second leaf of first tree
                value: 110,
                itemStyle: {
                  color: '#76D4FF'
                }
              }, {
                name: '贷款',       // Second leaf of first tree
                value: 90,
                itemStyle: {
                  color: '#76D4FF'
                }
              }]
            }]
          }]
        })
      }
    }
  }
</script>
<style scoped>
table {border-collapse: collapse;}
a {text-decoration: none;cursor: pointer;color: #000;}
li {list-style: none;}
.mar-t-10 {margin-top: 10px;}
.t-c {text-align: center;}
#app {background: #E9EEF3;}
.mar-t-20 {margin-top: 20px;}
.mar-l-100 {margin-left: 100px;}
.w-100 {width: 100%;overflow: hidden;}
.jbmb {margin: 0 auto;background: #fff;padding-bottom: 30px;width: 1380px;}
.jbmb .banner {text-align: center;line-height: 60px;height: 200px;background-image: url(../../../assets/images/common/banner_bg.png);background-repeat: no-repeat;background-size: 100% 100%;-moz-background-size: 100% 100%;color: #fff;margin-bottom: 30px;}
.jbmb .banner h1 {font-size: 30px;padding-top: 30px;line-height: 80px;}
.jbmb .banner span {padding: 0 30px;font-size: 22px;color: rgba(255, 255, 255, .79);}
.jbmb .cont {padding: 0 50px;}
.jbmb h2 {font-size: 24px;line-height: 60px;margin-top: 20px;}
.jbmb p,.jbmb .h3{font-size: 20px;line-height: 40px;color: rgba(0, 0, 0, .8);}
.jbmb p {text-indent: 2em;}
.box-card {box-shadow: 0px 3px 15px 0px rgba(194, 194, 194, 0.4);border-radius: 6px;padding: 20px 0;}
/*图标空*/
.echarts_empty {width: 100%;height: 380px;display: flex;justify-content: center;align-items: center;}
.echarts_empty .img {width: 94px;height: 120px;overflow: hidden;background: url(/static/img/echarts_empty.png) 0 0 no-repeat;}
/* 信息声量走势 */
.xxslzs {width: 66%;margin: 20px auto;}
</style>
